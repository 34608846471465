'use client'

import { useEffect } from 'react'

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    console.error('エラーが発生しました:', error)
  }, [error])

  return (
    <div className='flex min-h-screen flex-col items-center justify-center'>
      <h2 className='text-xl font-bold'>
        権限かクラウドサービスに問題が発生した可能性があります
      </h2>
      <div>はじめのログイン画面から再度お試しください</div>
      <div>問題が続くようであればURLのコピーとE-Mailを管理者にご連絡ください</div>
    </div>
  )
}
